var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("rest-table", {
    attrs: { endpoint: _vm.endpoint, fields: _vm.fields, filter: _vm.filter },
    scopedSlots: _vm._u([
      {
        key: "cell(Username)",
        fn: function(data) {
          return [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "portal-user-detail",
                    params: { id: data.item.PortalUserID }
                  }
                }
              },
              [_c("strong", [_vm._v(_vm._s(data.item.Username))])]
            )
          ]
        }
      },
      {
        key: "cell(LastLogin)",
        fn: function(data) {
          return [
            _vm._v(
              " " + _vm._s(_vm._f("dateFormat")(data.item.LastLogin)) + " "
            )
          ]
        }
      },
      {
        key: "cell(Provider)",
        fn: function(data) {
          return [_vm._v(" " + _vm._s(data.item.Provider.Description) + " ")]
        }
      },
      {
        key: "cell(PortalAdmin)",
        fn: function(data) {
          return [
            data.item.PortalAdmin ? _c("span", [_vm._v("Yes")]) : _vm._e(),
            !data.item.PortalAdmin ? _c("span", [_vm._v("No")]) : _vm._e()
          ]
        }
      },
      {
        key: "cell(actions)",
        fn: function(data) {
          return [
            _c(
              "b-button-group",
              [
                _vm.$root.$data.portalInfo.code != "thso"
                  ? _c(
                      "b-button",
                      {
                        attrs: {
                          to: {
                            name: "portal-user-detail",
                            params: { id: data.item.PortalUserID }
                          }
                        }
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "pencil-square",
                            "aria-hidden": "true"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                data.item.Active === 1 && _vm.$root.$data.me.IsSuperUser
                  ? _c(
                      "b-button",
                      {
                        attrs: { title: "Impersonate" },
                        on: {
                          click: function($event) {
                            return _vm.impersonate(data.item.PortalUserID)
                          }
                        }
                      },
                      [
                        _c("b-icon", {
                          attrs: { icon: "person-badge", "aria-hidden": "true" }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }