<template>
  <rest-table
      :endpoint="endpoint"
      :fields="fields"
      :filter="filter">

    <template v-slot:cell(Username)="data">
      <router-link :to="{ name: 'portal-user-detail', params: { id: data.item.PortalUserID } }">
        <strong>{{ data.item.Username }}</strong>
      </router-link>
    </template>

    <template v-slot:cell(LastLogin)="data">
      {{ data.item.LastLogin|dateFormat }}
    </template>

    <template v-slot:cell(Provider)="data">
      {{ data.item.Provider.Description }}
    </template>

    <template v-slot:cell(PortalAdmin)="data">
      <span v-if="data.item.PortalAdmin">Yes</span>
      <span v-if="!data.item.PortalAdmin">No</span>
    </template>

    <template v-slot:cell(actions)="data">
      <b-button-group>
        <b-button 
          v-if="$root.$data.portalInfo.code != 'thso'" 
          :to="{ name: 'portal-user-detail', params: { id: data.item.PortalUserID } }"
        >
          <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
        </b-button>

        <b-button
          v-if="data.item.Active === 1 && $root.$data.me.IsSuperUser" 
          @click="impersonate(data.item.PortalUserID)"
          title="Impersonate"
        >
          <b-icon icon="person-badge" aria-hidden="true"></b-icon>
        </b-button>
      </b-button-group>
    </template>
  </rest-table>
</template>

<script>
  export default {
    name: 'UserTable',
    components: {
      'rest-table': () => import('@/components/RestTable.vue'),
    },
    data () {
      return {
        filter: {}
      }
    },
    props: {
      endpoint: String,
    },
    methods: {

    },
    computed: {
      fields () {
        let out = [
          {
            key: 'Username',
            label: 'Username',
            sortable: true,
          },
          {
            key: 'FullName',
            label: 'Full name',
            sortable: true,
          },
          {
            key: 'EMail',
            label: 'E-mail',
            sortable: true,
          },
          {
            key: 'PortalAdmin',
            label: 'Admin',
            sortable: true,
          },
          {
            key: 'LastLogin',
            label: 'Last login',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Actions',
            class: 'link-icons',
          },
        ]

        if (this.$root.$data.me.IsSuperUser && !this.$root.$data.me.Provider) {
          out.unshift({
            key: 'Provider',
            label: 'Provider',
            sortable: false,
          })
        }
        
        return out
      },
    },
    mounted () {
    }
  }
</script>

<style scoped>
  label {
    font-weight: bold;
  }

  v-select {
    height: calc(1.5em + 0.75rem + 2px);
  }

  .inline {
    width: auto;
    display: inline-block;
  }
</style>